import {
  getInflationPrice,
  getLocalStorage,
  isEmptyObject,
} from 'utils';
import moment from 'moment';
import { LOCAL_STORAGE_KEY_CONFIGS } from 'features/app';
import {
  FILTER,
  IGeoJson,
  IGetFilterProps,
  IIndexedTitle,
  IPropertiesApiState,
  IProperty,
  ISearchSuggestion,
  ISearchSuggestionResponse,
  PROPERTY_TYPE_CONST,
  SOLDOUTFILTERCONFIG,
} from './typings';

const getMonthFormatDate = (subtract: number = 0) => {
  return moment().subtract(subtract, 'months').format('YYYY-MM-DD');
};

export const getSortQueryParams = ({ isOpenSearch = false, data }: any) => {
  if (!data) return data;
  return Object?.entries(data)?.map(([key, val]: any) => (isOpenSearch
    ? ({ [key]: val })
    : ({ direction: val, field: key })));
};

export const getFilterQueryParams = ({
  selectedFilter = {},
  selectedAddress = [],
}: IGetFilterProps) => {
  const filterDataKeys: any = {
    [FILTER.SOLD]: 'soldAt',
    [FILTER.TYPE]: 'propertyType',
    [FILTER.SIZE]: 'squareMeters',
    [FILTER.PRICE]: 'price',
    [FILTER.PRICE_PER_SQMETER]: 'pricePerSquareMeter',
    [FILTER.ROOM]: 'rooms',
    [FILTER.BATHROOM]: 'bathrooms',
    [FILTER.MATCHWITHADS]: 'ads',
    [FILTER.AVAILABLEFORSALE]: 'availableForSale',
    [FILTER.CONSTRUCTION_YEAR]: 'constructionYear',
  };
  const propertyTypeKeys: any = {
    Fjölbýlishús: 0,
    Einbýlishús: 1,
    Raðhús: 2,
    Sumarhús: 3,
    'Jörð/Lóð': 4,
    Parhús: 5,
    Atvinnuhúsn: 6,
    Hesthús: 7,
    Annað: 8,
    Fyrirtæki: 9,
    'Bílskúr/geymsla/skýli': 10,
    'Tví/Þrí/Fjórbýli': 11,
    Hæð: 12,
    'Bátur/skip': 13,
  };
  const tempFilter: any = { or: [], and: [] };
  if (!isEmptyObject(selectedFilter)) {
    Object?.entries(selectedFilter)?.forEach(([k, v]: any) => {
      const key: string = filterDataKeys?.[k];
      switch (k) {
        case FILTER.TYPE:
          if (v?.length) {
            tempFilter.and.push({
              type: 2,
              key,
              value: v?.map((type: string) => (
                propertyTypeKeys[type]
              )),
            });
          }
          break;
        case FILTER.AVAILABLEFORSALE:
          if (v) tempFilter.or.push({ type: 2, key, value: [1] });
          else tempFilter.and.push({ type: 2, key, value: [0] });
          break;
        case FILTER.MATCHWITHADS:
          if (v) tempFilter.and.push({ type: 0, key, value: 1 });
          break;
        case FILTER.SOLD:
          if (typeof v === 'string') {
            const minDate = getMonthFormatDate(
              v === SOLDOUTFILTERCONFIG.LAST_SIX_MONTHS ? 6 : 3,
            );
            tempFilter.or.push({
              type: 1,
              key,
              value: [moment(minDate).unix(), moment().unix()],
            });
          } else {
            tempFilter.or.push({
              type: 1,
              key,
              value: [v?.min, v?.max],
            });
          }
          break;
        case FILTER.PRICE:
          tempFilter.and.push({ type: 1, key, value: [v.min * 10_00_000, v.max * 10_00_000] });
          break;
        case FILTER.PRICE_PER_SQMETER:
          tempFilter.and.push({ type: 1, key, value: [v.min * 1000, v.max * 1000] });
          break;
        case FILTER.SIZE:
          tempFilter.and.push({ type: 1, key, value: [v?.min, v.max + 0.9] });
          break;
        default:
          tempFilter.and.push({ type: 1, key, value: [v?.min, v?.max] });
          break;
      }
    });
    if (!selectedFilter?.[FILTER.SOLD]) {
      tempFilter?.or?.push({
        type: 1,
        key: filterDataKeys[FILTER.SOLD],
        value: [-1, moment().unix()],
      });
    }
  }
  if (selectedAddress?.length) {
    const postcodes: (number | any[])[] = [];
    selectedAddress?.forEach((item: any) => {
      const [postcode, streetName, doorNo] = item?.value?.split(' ') || [];
      const address = [streetName, doorNo]?.filter(Boolean)?.join(' ');
      if (!address && postcode && !postcodes?.includes(Number(postcode))) {
        postcodes.push(Number(postcode));
      } else if (!postcodes?.includes(item?.value)) postcodes.push(item?.value);
    });
    if (postcodes?.length) {
      tempFilter.and.push({ key: 'postcode', type: 3, value: postcodes });
    }
  }

  return tempFilter;
};

export const getTransformedOpensearchData = (data: any[] = []) => {
  if (!data?.length) return { estimatedPricePerSqMeter: 0, relatedPropertyIds: [], data: [] };
  let estimatedPricePerSqMeter: number = 0;
  let validItemCount: number = 0;
  const exludedIds = getLocalStorage(LOCAL_STORAGE_KEY_CONFIGS?.EXCLUDED_PROPERTY_IDS) || [];
  const relatedPropertyIds: string[] = data?.filter((item: any) => !exludedIds?.includes(item?.id))
    ?.map((item: any) => {
      const {
        recentDate,
        recentPrice = 0,
        squareMeters,
        propertyType,
        availableForSale,
        postcode,
      } = item || {};
      if (!squareMeters || !recentPrice) return item?.id;
      validItemCount += 1;
      const inflatedPrice = availableForSale ? recentPrice : getInflationPrice({
        year: moment?.unix(recentDate)
          ?.format('YYYY.MM')
          ?.replace('.', 'M'),
        price: recentPrice,
        type: propertyType,
        postcode,
      });
      const inflatedPricePerSqMeter = Math.round(inflatedPrice / squareMeters);
      estimatedPricePerSqMeter += inflatedPricePerSqMeter;
      return item?.id;
    })?.filter(Boolean);

  return {
    estimatedPricePerSqMeter: estimatedPricePerSqMeter
      ? Math.round(estimatedPricePerSqMeter / validItemCount) : 0,
    relatedPropertyIds,
    data,
  };
};

export const getYear = (subtract: number = 0, add: number = 0) => {
  const date = moment().format('YYYY-MM-DD');
  if (subtract) return moment.utc(date).subtract(subtract, 'years').unix();
  if (add) return moment.utc(date).add(add, 'years').unix();
  return moment.utc(date).unix();
};

export const getOptions = (arr: any[], suffix?: string) => {
  return arr?.map((val: any) => ({
    label: [val, suffix]?.filter(Boolean)?.join(' '),
    value: val,
  }));
};

export const filterDropdownConfigs: any = {
  [FILTER.SOLD]: {
    className: 'sold-at',
    type: 'dropdown',
    min: 2001,
    max: getYear(),
    doNumFormat: false,
    options: [
      { label: 'Last 3 months', value: SOLDOUTFILTERCONFIG.LAST_THREE_MONTHS },
      { label: 'Last 6 months', value: SOLDOUTFILTERCONFIG.LAST_SIX_MONTHS },
      { label: 'Last year', value: { min: getYear(1), max: getYear() } },
      { label: 'Last 2 years', value: { min: getYear(2), max: getYear() } },
      { label: 'Last 5 years', value: { min: getYear(5), max: getYear() } },
      { label: 'Last 10 years', value: { min: getYear(10), max: getYear() } },
      { label: 'Last 10 years+', value: { min: 2001, max: getYear() } },
    ],
  },
  [FILTER.TYPE]: {
    type: 'multi-checkbox',
    options: Object?.entries(PROPERTY_TYPE_CONST)?.map(([key, val]: any) => ({
      label: val,
      value: key,
    })),
  },
  [FILTER.SIZE]: {
    type: 'multi-range-slider',
    min: 40,
    max: 10000,
    rangeOptions: getOptions([40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 175,
      200, 250, 300, 400, 500, 1000, 5000, 10000]),
  },
  [FILTER.PRICE]: {
    type: 'multi-range-slider',
    min: 1,
    max: 100,
    suffix: ' m.kr.',
    rangeOptions: getOptions([1, 5, 10, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80,
      85, 90, 95, 100, 110, 120, 130, 140, 150, 200, 500, 1000], 'm.kr.'),
  },
  [FILTER.PRICE_PER_SQMETER]: {
    type: 'multi-range-slider',
    min: 0,
    max: 1000,
    suffix: ' þ.kr.',
    rangeOptions: getOptions([350, 400, 450, 500, 550, 600, 650, 700, 750, 800, 850, 900, 950,
      1000, 1050, 1100], 'þ.kr.'),
  },
  [FILTER.ROOM]: {
    type: 'multi-range-slider',
    min: 0,
    max: 5,
    rangeOptions: getOptions([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
  },
  [FILTER.BATHROOM]: {
    type: 'multi-range-slider',
    min: 0,
    max: 5,
    rangeOptions: getOptions([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
  },
  [FILTER.CONSTRUCTION_YEAR]: {
    type: 'multi-range-slider',
    min: 1900,
    max: moment().year(),
    doNumFormat: false,
  },
  [FILTER.MATCHWITHADS]: {
    label: 'Auglýsingar',
    type: 'checkbox',
  },
  [FILTER.AVAILABLEFORSALE]: {
    label: 'Til sölu',
    type: 'checkbox',
  },
};

export const getTransformedPropertyData = (data: any, queryParams?: any) => {
  return data?.map((item: any) => ({
    ...item,
    url: queryParams ? `/props/${item?.id}?${queryParams}` : `/props/${item?.id}`,
    propertyType: PROPERTY_TYPE_CONST?.[item?.propertyType]?.toLowerCase(),
    recentDate: item?.recentDate
      ? moment.unix(item?.recentDate).format('DD.MM.YYYY') : '',
  }));
};

export const getTransformedCoordinateData = (data: string = '') => {
  if (!data) return { items: [], total: 0 };
  const arrStr: string[] = data?.split('\n');
  const transformedData: string[] = arrStr?.slice(1, -1)?.map((item: string) => {
    const [id] = item.split(',') || [];
    return id;
  });
  return { items: transformedData, total: transformedData?.length };
};

export const getTransformedGeoJsonData = (data: string = '') => {
  if (!data) return { items: {}, total: 0 };
  const arrStr: string[] = data?.split('\n');
  const transformedData: { [key: string]: IGeoJson } = {};
  arrStr?.slice(1, -1)?.forEach((item: string) => {
    const [id, latitude, longitude] = item.split(',') || [];
    transformedData[id] = {
      id,
      geometry: {
        type: 'Point',
        coordinates: [parseFloat(longitude) || 0, parseFloat(latitude) || 0],
      },
      properties: { id },
    };
  });
  return { items: transformedData, total: Object?.keys(transformedData)?.length };
};

export const getTransformedPropertiesData = (response: IPropertiesApiState) => {
  return {
    ...response,
    total: response?.items?.length,
    items: response?.items?.map((item: IProperty) => ({
      ...item,
      inflatedPrice: item?.availableForSale ? (
        item?.recentPrice
      ) : getInflationPrice({
        year: moment?.unix(item?.recentDate)
          ?.format('YYYY.MM')
          ?.replace('.', 'M'),
        price: item?.recentPrice,
        type: item?.propertyType,
        postcode: item?.postcode,
      }),
      inflatedPricePerSqMeter: Math.round(item.inflatedPrice / item.squareMeters),
    })) || [],
  };
};

export const getAvgPricePerSqMeter = (data: any = []) => {
  if (!data?.length) return 0;
  let validItemCount: number = 0;
  const exludedIds = getLocalStorage(LOCAL_STORAGE_KEY_CONFIGS?.EXCLUDED_PROPERTY_IDS) || [];
  const avgPricePerSqMeter = data?.filter((item: any) => !exludedIds?.includes(item?.id))
    ?.reduce((total: number, item: any) => {
      if (!item?.squareMeters || !item?.price) {
        return total;
      }
      validItemCount += 1;
      if (item?.availableForSale) {
        total += Math.round(item.price / item.squareMeters);
      } else {
        const inflatedPrice = getInflationPrice({
          year: moment?.unix(item?.date)
            ?.format('YYYY.MM')
            ?.replace('.', 'M'),
          price: item?.price,
          type: item?.propertyType,
          postcode: item?.postcode,
        });
        total += Math.round(inflatedPrice / item.squareMeters);
      }
      return total;
    }, 0);

  return Math.round(avgPricePerSqMeter / validItemCount);
};

export const getTransformedPropertyIdsData = (data: string = '') => {
  if (!data) return { estimatedPricePerSqMeter: 0, relatedPropertyIds: [], items: [] };
  const arrStr: string[] = data?.split('\n');
  const relatedPropertyIds: string[] = [];
  const propertyTypeMap: any = {
    0: 'Fjölbýlishús',
    1: 'Einbýlishús',
    2: 'Raðhús',
    3: 'Sumarhús',
    4: 'Jörð/Lóð',
    5: 'Parhús',
    6: 'Atvinnuhúsn',
    7: 'Hesthús',
    8: 'Annað',
    9: 'Fyrirtæki',
    10: 'Bílskúr/geymsla/skýli',
    11: 'Tví/Þrí/Fjórbýli',
    12: 'Hæð',
    13: 'Bátur/skip',
  };
  const transformedData: any[] = arrStr?.slice(1, -1)?.map((item: string) => {
    const [
      id,
      price,
      soldAt,
      availableForSale,
      postcode,
      propertyType,
      squareMeters,
      pricePerSquareMeter,
      latitude,
      longitude,
    ] = item?.split(',') || [];
    relatedPropertyIds?.push(id);
    return {
      id,
      price: Number(price),
      date: Number(soldAt),
      availableForSale: Number(availableForSale) === 1,
      postcode: Number(postcode),
      propertyType: propertyTypeMap?.[propertyType],
      squareMeters: Number(squareMeters),
      pricePerSquareMeter: Number(pricePerSquareMeter),
      latitude: Number(latitude || 0),
      longitude: Number(longitude || 0),
    };
  });

  return {
    estimatedPricePerSqMeter: transformedData?.length
      ? getAvgPricePerSqMeter(transformedData)
      : 0,
    relatedPropertyIds,
    items: transformedData,
  };
};

export const getTransformedTitleData = (data: string = '') => {
  if (!data) return {};
  const arrStr: string[] = data?.split('\n');
  const transformedData: IIndexedTitle = {};
  arrStr?.slice(1, -1)?.forEach((item: string) => {
    const [id, title] = item?.split(',') || [];
    transformedData[id] = { id, title };
  });
  return transformedData;
};

export const isValidStreetName = (streetName: string = '') => {
  let isValid = true;
  for (let i = 0; i < streetName.length; i += 1) {
    const char = streetName?.[i];
    if (/\d/.test(char)) {
      isValid = false;
      break;
    }
  }
  return isValid;
};

export const getStreetNameFromTitle = (title: string = '') => {
  let streetName: string = '';
  if (!title?.trim()) return '';
  const streetNameArr: string[] = title?.trim()?.split(' ');
  const newStreetNameArr: string[] = [];
  for (let i = 0; i < streetNameArr?.length; i += 1) {
    const key = streetNameArr[i]?.trim();
    if (key) {
      if (key === '-') {
        if (i + 1 < streetNameArr?.length && isValidStreetName(streetNameArr[i + 1])) {
          newStreetNameArr.push(key);
        } else {
          break;
        }
      } else if (isValidStreetName(key)) {
        newStreetNameArr.push(key);
      } else if (i + 1 < streetNameArr?.length && isValidStreetName(streetNameArr[i + 1])) {
        newStreetNameArr.push(key);
      } else {
        break;
      }
    }
  }
  if (newStreetNameArr?.length) {
    streetName = newStreetNameArr?.join(' ');
  }
  return streetName;
};

export const getTransformedSearchSuggestion = ({ data, searchType }: ISearchSuggestionResponse) => {
  if (!data) return [];
  const arrStr: string[] = data?.split('\n');
  const updatedPostcodes: string[] = [];
  const transformedData: ISearchSuggestion[] = [];
  arrStr?.slice(1, -1)
    ?.filter(Boolean)
    ?.forEach((item: string, _, arr: string[]) => {
      const [address, doorNo, count] = item?.split(/,(?!\s)/) || [];
      const doorNoArr: string[] = doorNo.replace(/'/g, '').slice(2, -2).split(', ')?.filter(Boolean) || [];
      const addressVal: string = address?.replace(/'/g, '').slice(1, -1);
      const countArr: string[] = count?.replace(/'/g, '').slice(2, -2).split(', ')?.filter(Boolean) || [];
      const postcode: string = addressVal?.split(' ')?.[0];
      if (searchType === 'postcode' && !updatedPostcodes?.includes(postcode)) {
        updatedPostcodes.push(postcode);
        transformedData.push({ label: postcode, value: postcode, children: [] });
      }
      if (['address', 'full_address']?.includes(searchType)) {
        if (arr?.length === 1 && searchType !== 'full_address') {
          transformedData.push({
            label: addressVal,
            value: addressVal,
            children: [],
          });
        }
        doorNoArr?.forEach((val: string, index: number) => {
          transformedData.push({
            label: [addressVal, val].join(' '),
            value: [addressVal, val].join(' '),
            count: Number(countArr?.[index]),
          });
        });
      } else {
        transformedData.push({
          label: addressVal,
          value: addressVal,
          children: doorNoArr?.map((val: string, index: number) => ({
            label: [addressVal, val].join(' '),
            value: [addressVal, val].join(' '),
            count: Number(countArr?.[index]),
          })),
        });
      }
    });
  return transformedData;
};

export const getTransformedAddressSuggesstionList = (data: string) => {
  if (!data) return [];
  const transformedAddresses: ISearchSuggestion[] = [];
  const arrStr: string[] = data?.split('\n');
  arrStr?.slice(1, -1)
    ?.filter(Boolean)
    ?.forEach((item: string) => {
      const [title, postcode] = item?.split(',') || [];
      const address = `${postcode} ${title}`;
      transformedAddresses.push({ label: address, value: address });
    });
  return transformedAddresses;
};
