import { gql } from '@apollo/client';

export const listMapProperties = gql`
  query listMapProperties($filter: FilterInput, $source: [String], $sort: Any, $hasAddress: Boolean) {
    listMapProperties(filter: $filter, source: $source, sort: $sort, hasAddress: $hasAddress)
  }
`;

export const listProperties = gql`
  query listProperties($ids: [String], $sort: Any) {
    listProperties(ids: $ids, sort: $sort) {
      items {
        id
        availableForSale
        bathrooms
        bedrooms
        constructionYear
        images
        nextYearValuationValue
        postcode
        pricePerSquareMeter
        propertyType
        recentPrice
        recentDate
        rooms
        squareMeters
        title
        latitude
        longitude
      }
    }
  }
`;

export const listSavelists = gql`
  query listSavelists($createdBy: String!, $limit: Int, $nextToken: String, $modifiedAt: Float) {
    listSavelists(createdBy: $createdBy, limit: $limit, nextToken: $nextToken, modifiedAt: $modifiedAt) {
      items {
        id
        name
        relatedPropertyIds
        addressTitle
        propertyType
        nextYearValuationValue
        fireInsuranceTotalValue
        constructionYear
        squareMeters
        storages
        rooms
        bathrooms
        bedrooms
        estimatedPricePerSqMeter
        postcode
        createdBy
        favourites
        editedPricePerSqMeter
        sharedId
        image
        propertyId
        sales
      }
      nextToken
    }
  }
`;

export const listAds = gql`
  query listAds($ids: [String]) {
    listAds(ids: $ids) {
      items {
        id
        dataSource
        price
        nextYearValuationValue
        fireInsuranceTotalValue
        whenCreated
        postcode
        propertyType
      }
    }
  }
`;

export const listSales = gql`
  query listSales($ids: [String]) {
    listSales(ids: $ids) {
      items {
        id
        price
        nextYearValuationValue
        registrationDate
        postcode
        propertyType
      }
    }
  }
`;

export const listPropertiesByAddress = gql`
  query listPropertiesByAddress($postcode: Int!, $title: String!) {
    listPropertiesByAddress(postcode: $postcode, title: $title) {
      items {
        id
        availableForSale
        bathrooms
        bedrooms
        constructionYear
        images
        nextYearValuationValue
        postcode
        pricePerSquareMeter
        propertyType
        recentPrice
        recentDate
        rooms
        squareMeters
        title
        storages
      }
    }
  }
`;

export const listTitles = gql`
  query listTitles($ids: [Int], $source: [String]) {
    listTitles(ids: $ids, source: $source)
  }
`;

export const listStreetNames = gql`
  query listStreetNames($source: [String], $name: String!) {
    listStreetNames(source: $source, name: $name) {
      data
      searchType
    }
  }
`;

export const getSavelist = gql`
  query getSavelist($id: ID!, $modifiedAt: Float, $createdBy: String) {
    getSavelist(id: $id, modifiedAt: $modifiedAt, createdBy: $createdBy) {
      id
      name
      relatedPropertyIds
      addressTitle
      propertyType
      nextYearValuationValue
      fireInsuranceTotalValue
      constructionYear
      squareMeters
      storages
      rooms
      bathrooms
      bedrooms
      estimatedPricePerSqMeter
      postcode
      createdBy
      favourites
      editedPricePerSqMeter
      sharedId
      image
      propertyId
      sales
    }
  }
`;

export const getProperty = gql`
  query getProperty($id: ID!) {
    getProperty(id: $id) {
      id
      ads
      apartmentNumber
      availableForSale
      balcony
      bathrooms
      bedrooms
      constructionMaterial
      constructionStatus
      constructionYear
      dataSource
      decodedStreetName
      decodedTitle
      description
      drainageStatus
      electricityStatus
      entranceType
      epilog
      fireInsuranceTotalValue
      floorNumber
      floorsInBuilding
      floorsInProperty
      garden
      geometry {
        type
        coordinates
      }
      guestBathrooms
      hasAdditionalApartment
      hasElevator
      hasNoActiveHousingAssociation
      hasPreemptiveRights
      hasView
      heating
      housingAssociationPrice
      images
      isAvailableNow
      isOverFifty
      isOverFiftyFive
      isOverSixty
      isOverSixtyFive
      isPetAllowed
      isSeniorCitizenPlace
      isWheelchairAccessible
      kitchens
      landRights
      landValue
      latitude
      laundryRooms
      livingRooms
      longitude
      newConstruction
      nextYearValuationValue
      paneStatus
      plotArea
      postcode
      pricePerSquareMeter
      propertyType
      rawPropertyType
      recentPrice
      recentDate
      roofStatus
      rooms
      sales
      squareMeters
      storages
      streetName
      title
      valuationStatus
      waterCost
      waterPipesStatus
      createdAt
      updatedAt
      indexedAt
    }
  }
`;

export const getGeoJson = gql`
  query getGeoJson {
    geoJson
  }
`;

export const getPropertyAddresses = gql`
  query getPropertyAddresses {
    getPropertyAddresses
  }
`;
